<template>
	<Suspense>
		<template #default>
			<div id="app_wrap" class="flex min-h-screen flex-col justify-between antialiased">
				<SideBar @clientChange="renderKey++" :show="$route.path !== '/login' && $route.meta.role != '*' && userStore.user != undefined">
					<RouterView :key="renderKey"></RouterView>
				</SideBar>

				<FooterBar v-if="userStore.user" v-show="$route.path != '/login'" />
			</div>
		</template>
		<template #fallback>
			<LoadingSpinner class="mx-auto my-96" />
		</template>
	</Suspense>
</template>

<script setup lang="ts">
import { useUserStore } from "@/stores/user";
import { ref, watch, watchEffect } from "vue";

const userStore = useUserStore();

const renderKey = ref(0);
</script>
